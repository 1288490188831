<template>
  <div>
    <div class="page-title">
      <h3>Добавление домашней локации</h3>
      <span>
        <a class="btn-floating btn-large waves-effect waves-light red"
           v-tooltip="'Вернуться'"
           @click="$router.push('/home_locations')">
          <i class="material-icons">arrow_back</i>
        </a>
      </span>
    </div>

    <form class="form" @submit.prevent="submitHandler">
      <div class="row">
        <div class="input-field">
          <input id="name" type="text" v-model="name" :class="{invalid: $v.name.$error}">
          <label for="name">Название</label>
          <small class="helper-text invalid" v-if="$v.name.$error">Не указано название</small>
        </div>
      </div>

      <div class="row">
        <p>Укажите геолокационные точки для формирования полигона домашней локации</p>
        <small class="helper-text invalid" v-if="polygon_error">Некорректные значения координат</small>
        <table class="striped">
          <thead>
          <th></th>
          <th>Широта (Lat)</th>
          <th>Долгота (Lng)</th>
          <th></th>
          </thead>
          <tbody>
          <tr v-for="(record, idx) of polygon" :key="record.id">
            <td>
              <a class="btn-small waves-effect waves-light red darken-4" @click="deletePoint(record)">
                <i class="material-icons tiny">delete_forever</i>
              </a>
            </td>
            <td>
              <input type="text" v-model="record.lat">
            </td>
            <td>
              <input type="text" v-model="record.lng">
            </td>
            <td>
              <a class="btn-small waves-effect waves-light blue darken-4" @click="toUpPoint(record)">
                <i class="material-icons tiny">keyboard_arrow_up</i>
              </a>
              <a class="btn-small waves-effect waves-light blue darken-4" @click="toDownPoint(record)">
                <i class="material-icons tiny">keyboard_arrow_down</i>
              </a>
            </td>
          </tr>
          </tbody>
          <tr>
            <td colspan="2">
              <a class="btn-small waves-effect waves-light blue darken-4" @click="addPoint">Добавить вершину</a>
            </td>
            <td colspan="2">
              <a class="btn-small waves-effect waves-light blue darken-4" v-if="isPolygonValid" @click="openPolygon">Проверить полигон</a>
            </td>
          </tr>
        </table>
      </div>

      <button class="btn waves-effect waves-light blue darken-4" type="submit">
        Сохранить
        <i class="material-icons right">send</i>
      </button>
    </form>

    <div id="modalPolygon" class="modal">
      <div class="modal-content">
        <vl-map v-if="isPolygonValid" data-projection="EPSG:4326" style="height: 350px" ref="map">
          <vl-view :zoom.sync="zoom" :center.sync="center"></vl-view>

          <vl-layer-tile>
            <vl-source-osm></vl-source-osm>
          </vl-layer-tile>

          <vl-feature>
            <vl-geom-polygon v-bind:coordinates="getCoordinates()"></vl-geom-polygon>
          </vl-feature>
        </vl-map>
      </div>
      <div class="modal-footer">
        <a href="#!" class="modal-close waves-effect blue darken-4 btn-flat white-text">Закрыть</a>
      </div>
    </div>

  </div>
</template>

<script>
import {required, numeric, decimal} from 'vuelidate/lib/validators'
import dateFilter from '../../filters/date.filter'
import convertDateString from '../../utils/date'

export default {
  metaInfo() {
    return {
      title: 'Добавление домашней локации'
    }
  },
  data: () => ({
    loading: true,
    id: '',
    name: '',
    map: null,
    polygon: [],
    zoom: 10,
    center: [],
    polygon_error: false,
    modalPolygon: null,
  }),
  validations: {
    name: {required},
  },
  async mounted() {
    setTimeout(() => {
      M.updateTextFields()
    })

    this.modalPolygon = M.Modal.init(document.querySelector('#modalPolygon'));
  },
  computed: {
    isPolygonValid() {
      if (this.polygon.length < 3) {
        return false;
      }

      for (let i = 0; i < this.polygon.length; i++) {
        if (isNaN(parseFloat(this.polygon[i].lat)) || this.polygon[i].lat === 0 || isNaN(parseFloat(this.polygon[i].lng)) || this.polygon[i].lng === 0) {
          return false;
        }
      }

      return true
    }
  },
  methods: {
    addPoint() {
      this.polygon.push({'lat': 0, 'lng': 0});
    },
    deletePoint(point) {
      this.polygon.splice(this.polygon.indexOf(point), 1);
    },
    toUpPoint(point) {
      let idx = this.polygon.indexOf(point)
      if (idx === 0) {
        return false;
      }

      let prev = this.polygon[idx - 1]
      this.polygon[idx - 1] = point
      this.polygon[idx] = prev

      let temp = this.polygon
      this.polygon = []
      this.polygon = temp
    },
    toDownPoint(point) {
      let idx = this.polygon.indexOf(point)
      if (idx === this.polygon.length - 1) {
        return false;
      }

      let next = this.polygon[idx + 1]
      this.polygon[idx + 1] = point
      this.polygon[idx] = next

      let temp = this.polygon
      this.polygon = []
      this.polygon = temp
    },
    openPolygon() {
      this.center = [parseFloat(this.polygon[0].lng), parseFloat(this.polygon[0].lat)]
      this.modalPolygon.open();
      this.$refs.map.refresh();
    },
    getCoordinates() {
      let coordinates = []

      for (let i = 0; i < this.polygon.length; i++) {
        coordinates.push([parseFloat(this.polygon[i].lng), parseFloat(this.polygon[i].lat)])
      }

      return [coordinates]
    },
    async submitHandler() {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }

      if (!this.isPolygonValid) {
        this.polygon_error = true;
        return false;
      }

      let polygon = [];
      for (let i = 0; i < this.polygon.length; i++) {
        polygon.push({'lat': parseFloat(this.polygon[i].lat), 'lng': parseFloat(this.polygon[i].lng)})
      }

      const homeLocation = {
        name: this.name,
        polygon: polygon,
      }

      await this.$store.dispatch('createHomeLocation', homeLocation)
      await this.$router.push('/home_locations')
    },
  },
  destroyed() {
    this.modalPolygon.destroy();
  },
}
</script>
